import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-action-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.css'],
})
export class ActionButtonComponent {
  @Input() disabled: boolean = false;
  @Input() textButton: string = '';
  @Input() whiteButton: boolean = false;
  @Input() buttonHeight: string = '56px';
  @Input() buttonWidth: string = '350px';

}
