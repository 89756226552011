import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { AuthRepository } from '@domain/repositories/auth.repository';
import { AuthenticationModel } from '@domain/models/auth.model';
import { UseCase } from '@domain/base/use-case';



export class RefreshTokenUseCase
  implements UseCase<{ token: string }, AuthenticationModel>
{
  constructor(private authRepository:AuthRepository ) { }

  execute(params: { token: string }): Observable<AuthenticationModel> {
    return this.authRepository.refreshToken(params.token);
  }
}
