import { Injectable } from '@angular/core';

import { AUTHENTICATION_DATA_KEY } from '../../../utils/constants/constants';
import {
  getData,
  removeData,
  setData,
} from '../../../utils/localStorageHandler/localStorageHandler.helper';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { RefreshTokenUseCase } from '../../../../../domain/usecases/auth/refresh-token.usecase';
import { AuthenticationModel } from '../../../../../domain/models/auth.model';

export interface Credential {
  email: string;
  password: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private refreshTokenUseCase: RefreshTokenUseCase,
    private router: Router
  ) {}

  public setAuthData(authData: AuthenticationModel): void {
    setData(AUTHENTICATION_DATA_KEY, { a: btoa(JSON.stringify(authData)) });
  }

  public isAuthenticated(): boolean {
    return !!this.getAuthData();
  }

  public getToken(): string {
    return this.getAuthData()?.idToken ?? '';
  }

  public getRefreshToken(): string {
    return this.getAuthData()?.refreshToken ?? '';
  }

  public isTokenExpired(): boolean {
    return this.tokenExpired(this.getToken());
  }

  public logout(): void {
    removeData(AUTHENTICATION_DATA_KEY);
    this.router.navigate(['/auth/login']);
  }

  public refreshToken(token: string): Observable<AuthenticationModel> {
    return this.refreshTokenUseCase.execute({ token });
  }

  public getAuthData(): AuthenticationModel | null {
    const authData = getData(AUTHENTICATION_DATA_KEY);

    if (!authData) {
      return null;
    }

    return JSON.parse(atob(authData.a));
  }

  private tokenExpired(token: string): boolean {
    if (!token) {
      return true;
    }

    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }
}
